.annotationtree__highlight_anno_1 {
  color: black;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.annotationtree__treeController {
  cursor: pointer;
  padding-top: 4px;
  padding-right: 6px;
  padding-left: 6px;
  float: left;
}

.annotationtree__treeIsOpen .annotationtree__openTree {
  display: block;
}

.annotationtree__treeIsClosed .annotationtree__openTree {
  display: none;
}

.annotationtree__treeIsOpen .annotationtree__closedTree {
  display: none;
}

.annotationtree__treeIsClosed .annotationtree__closedTree {
  display: block;
}

.annotationtree__treeBranchClosed {
  display: none !important;
}

.annotationtree__treeBranchOpen {
  display: block !important;
}

.annotationtree__counter {
  float: left;
  cursor: grab;
  margin-left: 10px;
  padding: 2px 6px 2px 6px;
  text-align: right;
  background-color: #e8e8e8;
  border-radius: 2px;
}

.annotationtree__treeRow {
  display: block;
}

.annotationtree__treeCell {
  padding-left: 2px;
  padding-top: 2px;
  float: left;
  height: 33px;
}

.annotationtree__highlight_anno_1:hover {
  color: blue;
  background-color: gray;
  /* Firefox */
  x-moz-transition: all 1s ease-in;
  /* WebKit */
  x-webkit-transition: all 1s ease-in;
  /* Opera */
  x-o-transition: all 1s ease-in;
  /* Standard */
  xtransition: all 1s ease-in;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}
